<template>
    <footer class="footer">
        <div class="footer__container">
            <router-link class="footer__logo-link" to="/">
                <img src="~@/assets/img/logo.svg" alt="GSLGames" width="225">
            </router-link>
            <div class="footer__copyright-wrapper">
                <ul class="footer__links-list">
                    <li class="footer__links-item">
                        <router-link class="footer__link" to="/privacy-policy/">Privacy Policy</router-link>
                    </li>
                    <li class="footer__links-item">
                        <router-link class="footer__link" to="/terms-of-use/">Terms of Service</router-link>
                    </li>
                </ul>
                <p class="footer__legal-address">
                    TOO «GSL Aiti» <br/>Address: 15 Elikhan Bokeykhan Street, Apt 25, Yesil District, Astana, <br/>Kazakhstan, 010000 <br/><br/>
                    Contact us: <a href="mailto:info@gslgames.com">info@gslgames.com</a>
                </p>
            </div>
        </div>
        <div class="footer__container">
            <p class="footer__copyright">
                © All Rights Reserved. TOO “GSL Aiti”
            </p>
        </div>
    </footer>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "../../styles/footer";
</style>
